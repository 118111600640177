import {
    CHALLENGE_TYPE,
    GAME_STEPS,
    GAME_STEPS_WITH_PITCH,
    GAME_STEPS_WITH_QUESTIONS,
    IS_LOCAL_ENV
} from "config/CONSTANTS";
import store from "redux/store";
import {i18nProvider, i18nService, SUPPORTED_LANGUAGES} from "i18n";
import GameUtil from "utils/GameUtil";
import i18nUtil from "utils/i18nUtil";
import TournamentService from "services/common/TournamentService";
import SwalModalUtil from "utils/SwalModalUtil";
import OverlayUtil from "utils/OverlayUtil";
import Utils from "utils/Utils";
import SkillCardModel from "models/SkillCardModel";
import TeamService from "services/player/TeamService";
import ToastUtil from "utils/ToastUtil";
import {setSelectedMobileTab} from "redux/slices/sessionSlice";
import {MOBILE_GAME_TABS} from "config/MOBILE";
import TOURNAMENT_CONFIG from "config/TOURNAMENT_CONFIG";


// if the money available for market is more than the set value, show a confirm before going to the next step
const MIN_AVAILABLE_TO_MARKET_TO_NOTIFY = 5000;


let _showAdvanceButton = false;
let _initialized = false;
let _tournamentT = null;


function getT() {
    return i18nService.getFixedT({
        ns: 'services/tournament/tournament_challenges'
    });
}

function checkIfKeyExistsForTournament(key) {
    return _tournamentT(key) !== key;
}

function translateForTournament(key, params = {}) {
    const gameLang = i18nService.getLanguage();
    const isMobile = store.getState().session.isMobile;
    const suffix = gameLang !== SUPPORTED_LANGUAGES.ENGLISH ? '_' + gameLang.toLowerCase() : '';

    const mobileKey = key + '_mobile';
    const mobileFullKey = mobileKey + suffix;
    const fullKey = key + suffix;

    if (checkIfKeyExistsForTournament(fullKey)) {
        return isMobile && checkIfKeyExistsForTournament(mobileFullKey)
            ? _tournamentT(mobileFullKey, params)
            : _tournamentT(fullKey, params);
    }

    return isMobile && checkIfKeyExistsForTournament(mobileKey)
        ? _tournamentT(mobileKey, params)
        : _tournamentT(key, params);
}

function init() {
    if (_initialized)
        return;

    const data = GameUtil.getTournamentData();
    i18nProvider.addResourceBundle(
        TOURNAMENT_CONFIG.TOURNAMENT_DATA_LANG,
        TOURNAMENT_CONFIG.TOURNAMENT_DATA_i18n_KEY,
        data.texts,
        true);

    _initialized = true;
    _tournamentT = i18nService.getFixedT({
        lang: TOURNAMENT_CONFIG.TOURNAMENT_DATA_LANG,
        ns: TOURNAMENT_CONFIG.TOURNAMENT_DATA_i18n_KEY
    });
}


async function skipConceptionCallback() {
    OverlayUtil.show();

    const startup = Utils.randomFromArray(store.getState().cards.startups);
    const founder1 = Utils.randomFromArray(store.getState().cards.skills);
    const founder1Icon = SkillCardModel.randomIconNumber();
    const founder2 = Utils.randomFromArray(store.getState().cards.skills);
    const founder2Icon = SkillCardModel.randomIconNumber();

    const vp1 = Utils.randomFromArray(store.getState().cards.valuePropositions);
    const vp2 = Utils.randomFromArray(store.getState().cards.valuePropositions);

    await Promise.all([
        TeamService.updateStartup({startup: startup.id}),
        TeamService.updateFounder({founder: {id: founder1.id, number: 0, icon: founder1Icon}}),
        TeamService.updateFounder({founder: {id: founder2.id, number: 1, icon: founder2Icon}}),
        TeamService.updateValueProposition({valueProposition: {id: vp1.id, number: 0}}),
        TeamService.updateValueProposition({valueProposition: {id: vp2.id, number: 1}}),
    ]);

    OverlayUtil.hide();
}

async function advanceGame() {
    if (!await checkAvailableForMarket())
        return false;

    if (!await TournamentService.advanceGame()) {
        const t = i18nService.getFixedT({ns: 'common'});
        ToastUtil.toastDanger(t('toasts.advance_game_error.title'), t('toasts.advance_game_error.message'));

        return false;
    }

    store.dispatch(setSelectedMobileTab(MOBILE_GAME_TABS.CHALLENGES));

    return true;
}

async function checkAvailableForMarket() {
    const tournamentState = store.getState().tournament.state;

    if (!tournamentState.showAvailableForMarketChallenge || tournamentState.showAccountingChallenge)
        return true;


    const showBoardForTeam = store.getState().session.showBoardForTeam;
    const availableForMarket = store.getState().teams[showBoardForTeam].accounting?.totals?.availableForMarket;

    if (availableForMarket <= MIN_AVAILABLE_TO_MARKET_TO_NOTIFY)
        return true;

    const availableForMarketFormatted = i18nUtil.formatMoney(availableForMarket);
    const t = getT();

    return SwalModalUtil.confirmModal(
        t('available_for_market_confirm.title'),
        t('available_for_market_confirm.message', {amount: availableForMarketFormatted}),
        t('available_for_market_confirm.confirm'),
        t('available_for_market_confirm.cancel')
    ).then(res => {

        if (res)
            return true;


        openMarket();

        return false;
    });
}

function advanceGameSecondaryButton() {
    if (store.getState().session.isMobile) {
        _showAdvanceButton = true;

        return {};
    }

    return {
        showSecondaryActionButton: true,
        showLoadingOnSecondaryClick: true,
        secondaryActionButtonText: getT()('buttons.advance'),
        secondaryActionButtonClass: 'btn btn-success shadow',
        secondaryActionCallback: advanceGame,
    }
}


function openMarket() {
    const btnToClick = store.getState().session.isMobile
        ? 'btn-hirings-details'
        : 'btn-open-skill-market';

    document.getElementById(btnToClick).click();
}

function tfg(key, params = {}) {
    return i18nUtil.translateForGame(getT(), key, params);
}


const ChallengesComponentService = {
    touchChallengesList(handleShowConceptionModal, handleShowTournamentPitchModal, handleShowNpsModal) {
        init();

        const t = getT();
        let res = [];

        const currentStep = store.getState().game.currentStep;
        const tournamentState = store.getState().tournament.state;

        const showBoardForTeam = store.getState().session.showBoardForTeam;
        const teamData = store.getState().teams[showBoardForTeam];
        const playerData = store.getState().session.user;

        const signedAccounting = teamData.signedAccounting;
        const hiredAccountingService = teamData.hiredAccountingService;

        _showAdvanceButton = false;

        // -------------------- CONCEPTION --------------------
        if (currentStep === GAME_STEPS.CONCEPTION) {
            if (teamData && teamData.startup && teamData.founders && teamData.founders[0] && teamData.founders[1]
                && teamData.valuePropositions && teamData.valuePropositions[0] && teamData.valuePropositions[1]) {

                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title: translateForTournament('conception_messages.done.title'),
                        description: translateForTournament('conception_messages.done.message'),

                        showLoadingOnClick: false,

                        // primary action
                        actionButtonText: t('conception.change'),
                        actionCallback: handleShowConceptionModal,

                        // secondary action
                        ...advanceGameSecondaryButton()
                    }
                });
            } else {
                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title: translateForTournament('conception_messages.not_done.title'),
                        description: translateForTournament('conception_messages.not_done.message'),
                        showLoadingOnClick: false,

                        // primary action
                        actionButtonText: t('conception.choose'),
                        actionCallback: handleShowConceptionModal,

                        // secondary action
                        showSecondaryActionButton: IS_LOCAL_ENV,
                        secondaryActionButtonText: 'Pular concepção',
                        secondaryActionCallback: skipConceptionCallback,
                    }
                });
            }
        }


        // -------------------- END GAME --------------------
        if (currentStep === GAME_STEPS.END_GAME && playerData.npsCount === 0) {
            res.push({
                type: CHALLENGE_TYPE.OBJECTIVE,
                data: {
                    title: 'Avalie a sua experiência!',
                    description: 'Antes de revelar o seu ranking, nos conte como foi a sua experiência!<br/><br/> Clique no botão abaixo para responder à um breve questionário para continuar.',
                    actionButtonText: 'Preencher avaliação',
                    actionCallback: handleShowNpsModal,
                }
            })
        }

        if (currentStep === GAME_STEPS.END_GAME && playerData.npsCount > 0) {
            const ranking = store.getState().game?.ranking?.[1];

            res.push({
                type: CHALLENGE_TYPE.TOURNAMENT_ENDING,
                data: {}
            });

            res.push({
                type: CHALLENGE_TYPE.OBJECTIVE,
                data: {
                    title: t('certificate_info.title'),
                    description: t('certificate_info.message'),
                }
            })

            if (ranking && ranking.globalRank) {
                const revenue = i18nUtil.formatMoney(ranking.revenue);

                res.push({
                    type: CHALLENGE_TYPE.TOURNAMENT_RANKING,
                    data: {
                        ranking: ranking.globalRank,
                        revenue
                    }
                });
            }


            res.push({
                type: CHALLENGE_TYPE.OBJECTIVE,
                data: {
                    title: t('play_again.title'),
                    description: tfg('play_again.message'),
                    actionButtonText: t('play_again.action'),
                    showLoadingOnClick: true,
                    actionCallback: () => {
                        return SwalModalUtil.confirmModal(
                            t('play_again_confirm.title'),
                            t('play_again_confirm.message'),
                            t('play_again_confirm.confirm'),
                            t('play_again_confirm.cancel'),
                        ).then(async res => {
                            if (res)
                                return TournamentService.closeSession();
                        })
                    }
                }
            });

            res.push({
                type: CHALLENGE_TYPE.OBJECTIVE,
                data: {
                    title: t('finished.title'),
                    description: t('finished.message'),
                    actionButtonText: t("finished.action"),
                    showLoadingOnClick: true,
                    actionCallback: async () => {
                        return TournamentService.closeSession();
                    }
                }
            });
        }


        // -------------------- PITCH --------------------
        if (GAME_STEPS_WITH_PITCH.includes(currentStep)) {
            const description = translateForTournament(`pitch.${currentStep}.message`);
            const secondaryButtonData = !!teamData.pitchData?.[currentStep]
                ? advanceGameSecondaryButton()
                : {};

            res.push({
                type: CHALLENGE_TYPE.OBJECTIVE,
                data: {
                    title: t('pitch.title'),
                    description,
                    actionButtonText: t('pitch.action'),
                    actionCallback: handleShowTournamentPitchModal,
                    ...secondaryButtonData
                }
            });
        }


        // -------------------- STEPS WITH QUESTIONS --------------------
        if (GAME_STEPS_WITH_QUESTIONS.includes(currentStep)) {

            if (teamData?.questions?.[currentStep]?.showResult !== true && !tournamentState.hasShownQuestionInfoCard) {
                const title = translateForTournament(`step_messages.${currentStep}.title`);
                const description = translateForTournament(`step_messages.${currentStep}.message`);
                const actionButtonText = t('buttons.advance');

                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title,
                        description,
                        actionButtonText,
                        actionCallback: () => TournamentService.touchTournamentState({hasShownQuestionInfoCard: true}),
                    }
                });
            } else if (tournamentState.showAccountingChallenge) {
                const title = translateForTournament(`accounting_messages.${currentStep}.title`);
                const description = translateForTournament(`accounting_messages.${currentStep}.message`);

                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title,
                        description,
                        showLoadingOnClick: true,

                        // primary action
                        actionButtonText: tfg('accounting_messages.action'),
                        actionCallback: async () => {
                            document.getElementById('btn-accounting-details').click()
                            return false;
                        },

                        // secondary action
                        ...advanceGameSecondaryButton()
                    }
                });
            } else if (tournamentState.showAvailableForMarketChallenge) {
                const lastQuestion = teamData?.questions?.[currentStep];
                const investmentConfig = lastQuestion?.card?.getInvestmentConfig();
                const wasQuestionCorrect = lastQuestion?.isCorrect === true;

                const hiredServices = teamData.services[currentStep];
                const hiredSkills = teamData.skills[currentStep];
                const canContinueGame = (hiredServices && hiredServices.length > 0) || (hiredSkills && hiredSkills.length > 0);

                const investmentReceived = wasQuestionCorrect
                    ? investmentConfig?.investmentRightAnswer
                    : investmentConfig?.investmentWrongAnswer;

                const parsedInvestment = i18nUtil.formatMoney(investmentReceived ?? 0);

                const title = translateForTournament(`market_messages.${currentStep}.title`, {amount: parsedInvestment});
                let description = translateForTournament(`market_messages.${currentStep}.message`, {amount: parsedInvestment});

                if (!canContinueGame)
                    description += `<br/><br/> <b class="text-warning">${t('market_messages.hire_info')}</b>`;

                const secondaryButton = canContinueGame
                    ? advanceGameSecondaryButton()
                    : {};


                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title,
                        description,
                        showLoadingOnClick: canContinueGame,

                        // primary action
                        actionButtonText: t('market_messages.action'),
                        actionCallback: openMarket,


                        ...secondaryButton
                    },
                });
            } else if (!teamData.unpredictabilities[currentStep]) {
                const teamQuestion = teamData?.questions[currentStep];

                if (teamQuestion)
                    res.push({type: CHALLENGE_TYPE.QUESTION, data: teamQuestion});

                if (teamQuestion.showResult === true)
                    advanceGameSecondaryButton();

            } else {
                const teamUnpredictability = teamData?.unpredictabilities[currentStep];

                if (teamUnpredictability)
                    res.push({type: CHALLENGE_TYPE.UNPREDICTABILITY, data: teamUnpredictability});

                if (teamUnpredictability?.reveal && teamUnpredictability?.revealOutcome)
                    advanceGameSecondaryButton();
            }
        }


        TournamentService.touchAdvanceButtonState({
            show: _showAdvanceButton,
            callback: advanceGame,
        });

        return res;
    }
};

export default ChallengesComponentService;
export {translateForTournament};
import React from "react";
import {Button, Modal} from "react-bootstrap";

import "pages/common/NpsFormModal/NpsFormModal.scss";
import NpsForm from "./components/NpsForm";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export default function NpsFormModal({onClose}) {
    const {t} = useTranslation('pages/common/nps_form');

    const isMobile = useSelector(state => state.session.isMobile);

    return (<Modal id="nps-form-modal" size={isMobile ? 'xl' : "lg"} show={true}>
        <Modal.Header>
            <Modal.Title>{t('texts.title')}</Modal.Title>
        </Modal.Header>


        <Modal.Body className="overflow-auto">
            <NpsForm onSubmit={onClose}/>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-between">
            <div className="flex-fill d-flex justify-content-end">
                <Button variant="secondary" onClick={onClose}>{t('buttons.close')}</Button>
            </div>
        </Modal.Footer>
    </Modal>);
}
import React, {useMemo, useState} from "react";
import NpsScore from "pages/common/NpsFormModal/components/NpsScore";
import {useSelector} from "react-redux";
import ButtonSpinner from "components/ButtonSpinner";
import LoggerService from "services/common/LoggerService";
import PlayerService from "services/common/PlayerService";
import ToastUtil from "utils/ToastUtil";
import {useTranslation} from "react-i18next";

const SCORES_COUNT = 10;
const COMMENT_MAX_LENGTH = 1000;

export default function NpsForm({onSubmit}) {
    const {t} = useTranslation('pages/common/nps_form', {keyPrefix: 'components.form'});
    const t2 = useTranslation('nps_questions').t;
    const t3 = useTranslation('common').t;

    const userId = useSelector(state => state.session.user.id);

    const [score, setScore] = useState(null);
    const [hasPlayedBefore, setHasPlayedBefore] = useState(false);
    const [experienceReview, setExperienceReview] = useState(null);
    const [comment, setComment] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const npsScores = useMemo(() => {
        const scores = [];

        for (let i = 1; i <= SCORES_COUNT; i++) {
            scores.push((<NpsScore key={i} value={i} disabled={isLoading} onChange={setScore}/>))
        }

        return scores;
    }, [isLoading]);


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (score === null || score === undefined || score === '' || !experienceReview || experienceReview.length < 1)
            return;

        setIsLoading(true);

        const parsedComment = !comment || comment.length <= COMMENT_MAX_LENGTH
            ? comment
            : comment.substring(0, COMMENT_MAX_LENGTH - 1);

        const parsedExperienceReview = !experienceReview || experienceReview.length <= COMMENT_MAX_LENGTH
            ? experienceReview
            : experienceReview.substring(0, COMMENT_MAX_LENGTH - 1);

        const params = {
            score,
            comment: parsedComment,
            extra: [
                {
                    question: 'tournament_beta.has_played_before',
                    answer: hasPlayedBefore ? 'true' : 'false',
                },
                {
                    question: 'tournament_beta.experience_review',
                    answer: parsedExperienceReview
                }
            ]
        };

        try {
            if ((await PlayerService.updateNps(userId, userId, params)) !== false) {
                onSubmit();
            } else {
                ToastUtil.toastDanger(t('toasts.update_error.title'), t('toasts.update_error.message'));
            }
        } catch (err) {
            LoggerService.error(err, {action: 'submit nps tournament', userId, params});
        } finally {
            setIsLoading(false);
        }
    };

    return (<div>
        <p className="mb-4">{t('texts.title')}</p>

        <form onSubmit={handleSubmit}>
            <div className="form-group score-container">
                <label className="required">{t('texts.recommend_probability')}</label>

                <ul className="nps-scores d-flex">{npsScores}</ul>

                <div className="d-flex justify-content-between subtitle small">
                    <p>{t('texts.low')}</p>
                    <p>{t('texts.high')}</p>
                </div>
            </div>

            <div className="mt-5 mb-3">
                <label className="required">
                    { t2('tournament_beta.has_played_before')}
                </label>

                <div className="form-check">
                    <input className="form-check-input" type="radio" name="has-played-before-checkbox"
                           id="has-played-before-checkbox-1"
                           disabled={isLoading}
                           onChange={() => setHasPlayedBefore(true)}
                           checked={hasPlayedBefore === true}/>
                    <label className="form-check-label" htmlFor="has-played-before-checkbox-1">
                        {t3('yes')}
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="has-played-before-checkbox"
                           id="has-played-before-checkbox-2"
                           disabled={isLoading}
                           onChange={() => setHasPlayedBefore(false)}
                           checked={hasPlayedBefore === false}/>
                    <label className="form-check-label" htmlFor="has-played-before-checkbox-2">
                        {t3('no')}
                    </label>
                </div>
            </div>

            <div className="form-group pt-4">
                <label className="required">
                    { t2('tournament_beta.experience_review')}
                </label>

                <textarea rows={5} maxLength={1000} className="form-control" name="comment"
                          onChange={event => setExperienceReview(event.target.value.trim())}
                          required
                          disabled={isLoading}></textarea>

                <p className="form-text text-muted small">
                    {t('texts.comment_length', {count: experienceReview?.length ?? 0, max: COMMENT_MAX_LENGTH})}
                </p>
            </div>

            <div className="form-group pt-4">
                <label>{t('texts.comment')} <span className="small text-muted">- {t('texts.optional')}</span></label>
                <textarea rows={5} maxLength={1000} className="form-control" name="comment"
                          onChange={event => setComment(event.target.value.trim())} disabled={isLoading}></textarea>

                <p className="form-text text-muted small">
                    {t('texts.comment_length', {count: comment?.length ?? 0, max: COMMENT_MAX_LENGTH})}
                </p>
            </div>

            <ButtonSpinner type="submit" showAnimation={isLoading}>
                {t('buttons.submit')}
            </ButtonSpinner>
        </form>
    </div>);
}